<template>
  <Layout>
    <template v-if="this.$route.name == 'add-config'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="config">
              <b-form-group>
                <label for="input-1"
                  >Config Name <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  v-model="form.config_name"
                  placeholder="Enter Config Name"
                  :class="{
                    'is-invalid': submitted && $v.form.config_name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.config_name.required"
                  class="invalid-feedback"
                >
                  Config Name is required.
                </div>
              </b-form-group>
              <b-form-group class="position-relative">
                <label for="input-2"
                  >Value <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-2"
                  :maxlength="250"
                  v-model="form.value"
                  placeholder="Enter Value"
                  :class="{
                    'is-invalid': submitted && $v.form.value.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.value.required"
                  class="invalid-feedback"
                >
                  Value is required.
                </div>
                <div class="text-right">
                  <p
                    v-if="form.value"
                    class="badge position-absolute"
                    style="top: 4px; right: 13px"
                    :class="{
                      'badge-success': form.value.length !== 250,
                      'badge-danger': form.value.length === 250,
                    }"
                  >
                    You typed
                    {{ form.value.length }} out of 250 chars.
                  </p>
                </div>
              </b-form-group>
              <b-form-group>
                <label for="input-4"
                  >Tell Us More About Config
                  <span style="color: red">*</span></label
                >
                <ckeditor
                  v-model="form.description"
                  :editor="editor"
                  :class="{
                    'is-invalid': submitted && $v.form.description.$error,
                  }"
                ></ckeditor>
                <div
                  v-if="submitted && !$v.form.description.required"
                  class="invalid-feedback"
                >
                  Description is required.
                </div>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                ><span v-if="this.$route.name == 'add-config'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import configMixin from "../../../mixins/ModuleJs/config";
import { required } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      submitted: false,
      title1: "Add Config",
      title2: "Edit Config",
      items: [
        {
          text: "List",
          href: "/config",
        },
        {
          text: "Data",
        },
      ],
      editor: ClassicEditor,
    };
  },
  mixins: [MixinRequest, configMixin],
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      config_name: { required },
      value: { required },
      description: { required },
    },
  },
};
</script>
